// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-01-js": () => import("./../src/pages/Page01.js" /* webpackChunkName: "component---src-pages-page-01-js" */),
  "component---src-pages-page-02-js": () => import("./../src/pages/Page02.js" /* webpackChunkName: "component---src-pages-page-02-js" */),
  "component---src-pages-page-03-js": () => import("./../src/pages/Page03.js" /* webpackChunkName: "component---src-pages-page-03-js" */),
  "component---src-pages-page-04-js": () => import("./../src/pages/Page04.js" /* webpackChunkName: "component---src-pages-page-04-js" */),
  "component---src-pages-page-05-js": () => import("./../src/pages/Page05.js" /* webpackChunkName: "component---src-pages-page-05-js" */),
  "component---src-pages-page-06-js": () => import("./../src/pages/Page06.js" /* webpackChunkName: "component---src-pages-page-06-js" */),
  "component---src-pages-page-07-js": () => import("./../src/pages/Page07.js" /* webpackChunkName: "component---src-pages-page-07-js" */),
  "component---src-pages-page-08-js": () => import("./../src/pages/Page08.js" /* webpackChunkName: "component---src-pages-page-08-js" */),
  "component---src-pages-page-09-js": () => import("./../src/pages/Page09.js" /* webpackChunkName: "component---src-pages-page-09-js" */),
  "component---src-pages-page-10-js": () => import("./../src/pages/Page10.js" /* webpackChunkName: "component---src-pages-page-10-js" */),
  "component---src-pages-page-11-js": () => import("./../src/pages/Page11.js" /* webpackChunkName: "component---src-pages-page-11-js" */),
  "component---src-pages-page-12-js": () => import("./../src/pages/Page12.js" /* webpackChunkName: "component---src-pages-page-12-js" */),
  "component---src-pages-page-13-js": () => import("./../src/pages/Page13.js" /* webpackChunkName: "component---src-pages-page-13-js" */)
}

